import img1 from "../assets/img/sliders/img1.jpg";
import img2 from "../assets/img/sliders/img2.jpg";
import img3 from "../assets/img/sliders/img3.jpg";
import img4 from "../assets/img/sliders/img4.jpg";
import img5 from "../assets/img/sliders/img5.jpg";
import img6 from "../assets/img/sliders/img6.jpg";
import img7 from "../assets/img/sliders/img7.jpg";
import img8 from "../assets/img/sliders/img8.jpg";
import img9 from "../assets/img/sliders/img9.jpg";
import img10 from "../assets/img/sliders/img10.jpg";
import img11 from "../assets/img/sliders/img11.jpg";
import img12 from "../assets/img/sliders/img12.jpg";
import img13 from "../assets/img/sliders/img13.jpg";
import img14 from "../assets/img/sliders/img14.jpg";
import img15 from "../assets/img/sliders/img15.jpg";
import img16 from "../assets/img/sliders/img16.jpg";
import img17 from "../assets/img/sliders/img17.jpg";
import img18 from "../assets/img/sliders/img18.jpg";
import img19 from "../assets/img/sliders/img19.jpg";
import img20 from "../assets/img/sliders/img20.jpg";
import img21 from "../assets/img/sliders/img21.jpg";
import img22 from "../assets/img/sliders/img22.jpg";
import img23 from "../assets/img/sliders/img23.jpg";
import img24 from "../assets/img/sliders/img24.jpeg";
import img25 from "../assets/img/sliders/img25.jpeg";
import img26 from "../assets/img/sliders/img26.webp";

export default {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
};
